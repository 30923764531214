<!--通用设备模块--设备卡片组件-->
<template>
  <div id="facilityEQ" @click="goDetails">
    <div class="title-box">
      <img :src="getImages" :class="getCls" />
      <div class="title" :title="cardContent.name">{{ cardContent.name }}</div>
    </div>

    <div style="position: relative;">
        <div v-if="cardContent.firDisPointId>0" :title="cardContent.firDisPointName">
            <div v-if="cardContent.firDisPointType==3 || cardContent.firDisPointType==4 || cardContent.firDisPointType==5" >
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.firDisPointType==13 || cardContent.firDisPointType==14 || cardContent.firDisPointType==19">
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.firDisPointPv }}</span>
                <span class="unit">{{ cardContent.firDisPointText }}</span>
            </div>

        </div>
        <div v-if="cardContent.secDisPointId>0" :title="cardContent.secDisPointName">
            <div v-if="cardContent.secDisPointType==3 || cardContent.secDisPointType==4 || cardContent.secDisPointType==5" >
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.secDisPointType==13 || cardContent.secDisPointType==14 || cardContent.secDisPointType==19">
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.secDisPointPv }}</span>
                <span class="unit">{{ cardContent.secDisPointText }}</span>
            </div>

        </div>
        <!-- v-if="cardContent.cameraId2>0" -->
        <div class="v-btn">
            <a-button v-if="cardContent.cameraId1>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId1)"/>
            <a-button v-if="cardContent.cameraId2>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId2)"/>
        </div >
    </div>
    
  </div>
</template>

<script>
export default {
  name: "facilityEQ",
  props: {
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      changeState: false,
    };
  },
  methods: {
    // 根据卡片类型跳转对应的详情页
    goDetails() {
      let type = this.cardContent.iconNo;
      let eqmid =  this.cardContent.id;
      this.$router.push({
          path: "/homepage/facility/facilityDetails",
          query: {
            id: eqmid,
            iconNo:type
          },
      });
    },
    getVideo(cameraId) {
      const data = {
        vdialog: true,
        cameraId: cameraId,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          cameraId: cameraId,
        }
      }
      console.log('getVideo>>>',data);
      this.$emit('hasVideoChange', data );
    },
  },
  computed: {
    // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      let iconNo = this.cardContent.iconNo.toString();
      return require("../../../../public/images/facility/" + iconNo + ".png");
    },
    getCls: function () {
      let iconNo = parseInt(this.cardContent.iconNo.toString().substring(0,2)+"0");
      let cls = '';
      // switch(iconNo){
      //   case 690:
      //   case 730:
      //   case 740:
      //   case 750:
      //   case 760:
      //   case 770:
      //   case 780:
      //   case 790:
      //   case 810:
      //     cls = 'cimg';
      //     break;
      //   default:
      //     break;
      // }
      return cls;
    },
  },
};
</script>

<style scoped>
#facilityEQ {
  width: 200px;
  height: 110px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px 10px;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
  float: left;
  font-family: "Microsoft YaHei";
}
#facilityEQ:hover {
  border: 1px solid #7682ce;
}
.title-box{
  display: flex;
  justify-content: flex-start;
}
.title{
    height: 48px;
    line-height: 48px;
    font-size: 14px;
   
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cimg{
  height: 36px;
  margin: 6px;
}

.pv {
  font-size: 16px;
  padding-left: 10px;
 
}
.unit{
  padding-left: 3px;
  font-size: 12px; 
}
.v-btn{
  position: absolute;
  right: 2%;
  bottom: 0%;
  display: grid;
}
.v-btn-2{
  position: absolute;
  right: 2%;
  bottom: 0%;
}
</style>